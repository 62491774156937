import { flow, Instance, types } from 'mobx-state-tree'

import { sendEvent } from 'src/utils/analytics'
import { EVENTS_AMPLITUDE } from 'src/constants/analytics/events'
import { AMPLITUDE } from 'src/constants/analytics'
import { RootAccessing } from 'src/store/models/root-accessing'
import { Experiment } from 'src/store/models/experiment'

import CHECKOUT_FLOW_APPLE_PAY from '../constants/routes'

export const CheckoutFlowApplePay = types
  .compose(RootAccessing, Experiment)
  .actions(self => ({
    purchase: flow(function* () {
      const { subscription } = self.rootStore
      yield subscription.purchase({
        successUrl: `${CHECKOUT_FLOW_APPLE_PAY.STORE_LINK}${self.queryParams}`,
        cancelUrl: `${CHECKOUT_FLOW_APPLE_PAY.PAYWALL}${self.queryParams}`,
        plan: self.planConfig,
      })

      sendEvent(EVENTS_AMPLITUDE.StripeShow, [AMPLITUDE], {
        exp_name: self.name
      })
    })
  }))

export interface ICheckoutFlowApplePay extends Instance<typeof CheckoutFlowApplePay> {
}
