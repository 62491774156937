import Kit from 'src/api/kit'
import AxiosService from 'src/api/services/axios-service'
import { requestMiddlewares, responseMiddlewares } from './middlewares'

const service = new AxiosService()

service.addRequestMiddleware(...requestMiddlewares)
service.addResponseMiddleware(...responseMiddlewares)

const API = new Kit(service)

export default API
