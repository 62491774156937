import React from 'react'

import { Typography, TypographyView } from 'src/components/typography'
import Svg from 'src/components/svg'

import checkmark from 'assets/icons/checkout-flow/checkmark.svg'
import oval from 'assets/icons/checkout-flow/oval.svg'

import { CheckmarkProps } from './types'
import { Wrapper } from './styles'

export const Checkmark: React.FC<CheckmarkProps> = ({
  checked,
  onClick,
  children,
}: CheckmarkProps) => {
  return (
    <Wrapper onClick={onClick}>
      <Svg icon={checked ? checkmark : oval} />
      <Typography view={TypographyView.SubHeadlineMedium}>
        {children}
      </Typography>
    </Wrapper>
  )
}

export default Checkmark
