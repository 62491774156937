import React from 'react'

export enum TypographyView {
  Title1 = 'title1',
  Title2 = 'title2',
  Title3 = 'title3',
  Headline = 'headline',
  BodyMedium = 'bodyMedium',
  BodyRegular = 'bodyRegular',
  SubHeadlineMedium = 'subheadlineMedium',
  SubHeadlineRegular = 'subheadlineRegular',
  CaptionMedium = 'captionMedium',
  CaptionRegular = 'captionRegular',
}

export interface TypographyProps {
  className?: string
  view?: TypographyView
  children?: React.ReactNode
}
