import SectionBase from 'src/api/sections/base'

import { STRIPE_ENTITY_CONFIGURATION } from 'src/constants/subscription/config'
import { PAYMENT_METHOD_TYPES } from 'src/constants/subscription/payment'
import { DEFAULT_TRIAL_PERIOD_DAYS, TrialType } from 'src/constants/subscription/trial'

import storeInstance from 'src/store/instance'

import {
  ISubscriptionCreateSessionOptions,
} from './types'

export default class Subscriptions extends SectionBase {
  public createStripeSession = ({
    successUrl,
    cancelUrl,
    customerEmail,
    plan,
  }: ISubscriptionCreateSessionOptions) => {
    const url = window.location.origin
    const userID: string = storeInstance.get().session.userID

    const data: any = {
      payment_method_types: PAYMENT_METHOD_TYPES,
      cancel_url: cancelUrl.includes('http') ? cancelUrl : `${url}${cancelUrl}#cancelled`,
      success_url: successUrl.includes('http') ? cancelUrl : `${url}${successUrl}#success`,
      user_id: userID,
      appsflyer_id: 'AppsflyerID1',
      subscription_data: [
        {
          plan: STRIPE_ENTITY_CONFIGURATION.PLAN,
          quantity: 1,
        },
      ],
      customer_email: customerEmail,
    }

    const { trialType, coupon } = plan

    if (trialType !== TrialType.none) {
      data.trial_period_days = DEFAULT_TRIAL_PERIOD_DAYS
    }

    if (trialType === TrialType.paid) {
      data.subscription_data.push({
        plan: STRIPE_ENTITY_CONFIGURATION.PLAN1$,
        quantity: 1,
      })
    }

    if (coupon) {
      data.coupon = coupon
    }

    return this.service.request({
      url: '/create_web_session',
      method: 'POST',
      data,
    })
  }
}
