import { flow, Instance, types } from 'mobx-state-tree'

import { CheckoutFlowStep } from 'src/modules/checkout-flow/constants/page'
import API from 'src/api'
import CHECKOUT_FLOW_ROUTES from '../constants/routes'
import { ROUTES } from 'src/router/constants'
import { sendEvent } from 'src/utils/analytics'
import { EVENTS_AMPLITUDE, EVENTS_FACEBOOK } from 'src/constants/analytics/events'
import { AMPLITUDE, FACEBOOK } from 'src/constants/analytics'
import { RootAccessing } from 'src/store/models/root-accessing'
import { Experiment } from 'src/store/models/experiment'

export const CheckoutFlowModel = types
  .model('CheckoutFlowModel', {
    step: types.optional(
      types.enumeration('CheckoutFlowStep', [...Object.values(CheckoutFlowStep)]),
      CheckoutFlowStep.init
    ),
  })
  .actions((self) => ({
    setStep(step: CheckoutFlowStep) {
      self.step = step
    }
  }))

export const CheckoutFlow = types
  .compose(CheckoutFlowModel, RootAccessing, Experiment)
  .actions(self => ({
    afterAuthentication: flow(function* (email: string, allowed: boolean) {
      sendEvent(EVENTS_FACEBOOK.RegisterSuccess, [FACEBOOK])
      sendEvent(EVENTS_AMPLITUDE.RegisterSuccess, [AMPLITUDE], {
        exp_name: self.name
      })

      const { subscription } = self.rootStore
      yield API.user.sendEmailAllowance(allowed)

      yield subscription.purchase({
        successUrl: `${CHECKOUT_FLOW_ROUTES.STORE}?plan=${self.plan}&exp_name=${self.name}`,
        cancelUrl: ROUTES.APP,
        customerEmail: email,
        plan: self.planConfig,
      })

      sendEvent(EVENTS_AMPLITUDE.StripeShow, [AMPLITUDE], {
        exp_name: self.name
      })
    }),
  }))

export interface ICheckoutFlow extends Instance<typeof CheckoutFlow> {}

export const HiddenCheckoutFlow = types
  .compose(CheckoutFlowModel, RootAccessing, Experiment)
  .actions(self => ({
    afterAuthentication: flow(function* (email: string) {
      sendEvent(EVENTS_FACEBOOK.RegisterSuccess, [FACEBOOK])
      sendEvent(EVENTS_AMPLITUDE.RegisterSuccess, [AMPLITUDE], {
        exp_name: self.name
      })

      const { subscription } = self.rootStore

      yield subscription.purchase({
        successUrl: `https://prisma-ai.com/${self.rootStore.session.app}`,
        cancelUrl: `https://prisma-ai.com/${self.rootStore.session.app}`,
        customerEmail: email,
        plan: self.planConfig,
      })

      sendEvent(EVENTS_AMPLITUDE.StripeShow, [AMPLITUDE], {
        exp_name: self.name
      })
    }),
  }))