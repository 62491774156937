import BASE_ROUTES from 'src/router/constants'

export const AUTH = `${BASE_ROUTES.CHECKOUT_FLOW}/auth`
export const STORE = `${BASE_ROUTES.CHECKOUT_FLOW}/store`
export const APPLE = `${BASE_ROUTES.CHECKOUT_FLOW}/apple`

const CHECKOUT_FLOW_ROUTES = {
  AUTH,
  STORE,
  APPLE,
}

export default CHECKOUT_FLOW_ROUTES
