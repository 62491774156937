import React from 'react'

import connector from 'src/decorators/connector'
import { TypographyView } from 'src/components/typography'
import { Button2, ButtonSize, ButtonView } from 'src/components/button'
import { IStoresMap } from 'src/store'

import { InputWrapper, SubTitle } from '../../styles'

import InputVerificationCode, { CODE_LENGTH } from './input-verification-code'

export const CODE_ERRORS = {
  IncorrectCode: 'Invalid Code',
}

export type FormProps = ReturnType<typeof storesToProps>

export interface IFormState {
  code: string
  loading: boolean
}

class Form extends React.Component<FormProps, IFormState> {
  public constructor(props: FormProps) {
    super(props)

    this.state = {
      code: '',
      loading: false,
    }
  }

  public isCodeValid(code: string): string {
    return code.length === CODE_LENGTH ? '' : CODE_ERRORS.IncorrectCode
  }

  public handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault()

    const { code } = this.state
    const { authentication, checkoutFlow } = this.props

    const error = this.isCodeValid(code)
    if (error) {
      authentication.setError(error)
      return
    }

    try {
      this.setState({ loading: true })
      await authentication.confirmByEmail({ code })
      await checkoutFlow.afterAuthentication(
        authentication.email,
        authentication.allowEmailSend
      )
    } finally {
      this.setState({ loading: false })
    }
  }

  public render() {
    const {
      authentication: { email, error },
    } = this.props
    const { code, loading } = this.state

    return (
      <form onSubmit={this.handleSubmit}>
        <SubTitle view={TypographyView.BodyMedium}>
          An email with the code has been sent {email && `to ${email}`}
        </SubTitle>

        <InputWrapper>
          <InputVerificationCode
            code={code}
            error={error}
            onChange={(c) => this.setState({ code: c })}
          />
        </InputWrapper>

        <Button2
          id="prisma_checkout-flow_confirm_btn-submit"
          view={ButtonView.CTA}
          size={ButtonSize.Large}
          isLoading={loading}
          fullWidth
        >
          Submit
        </Button2>
      </form>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  authentication: store.authentication,
  checkoutFlow: store.checkoutFlow,
})

export default connector(storesToProps)(Form)
