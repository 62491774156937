import styled from 'src/styles'

import bg from 'assets/images/checkout-flow/checkout_flow_bg.png'

export const Wrapper = styled.div`
  position: fixed;

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  background-image: url(${bg});
  background-color: ${(props) => props.theme.palette.background.base};
  background-size: cover;
  
  width: 100vw;
  height: 100vh;
  
  display: flex;
  justify-content: center;
  align-items: center;
`
