import * as styledComponents from 'styled-components'

import nextClassName from './next-class-name'
import theme, { Theme } from './theme'

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
  withTheme,
} = (styledComponents as any) as styledComponents.ThemedStyledComponentsModule<Theme>

interface IThemedProps {
  theme: Theme
}

export {
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
  withTheme,
  theme,
  IThemedProps,
  nextClassName,
}

export default styled
