import { TrialType } from 'src/constants/subscription/trial'

type CheckoutFlowStoreLinkOptions = {
  [key in TrialType]: string
}

export const CHECKOUT_FLOW_STORE_LINKS: CheckoutFlowStoreLinkOptions = {
  [TrialType.free]: 'https://prisma.onelink.me/CuJ7/cd2973e2',
  [TrialType.paid]: 'https://prisma.onelink.me/CuJ7/4e925c8b',
  [TrialType.none]: '', // don't have now
}
