import React from 'react'

import Svg from 'src/components/svg'
import passedStep from 'assets/icons/checkout-flow/passed_step.svg'

import { StyledStepper, Step, StepDivider } from './styles'
import { StepperProps, StepState } from './types'

export const Stepper: React.FC<StepperProps> = ({
  totalSteps,
  currentStep,
}: StepperProps) => {
  return (
    <StyledStepper>
      {[...new Array(totalSteps)].map((_, index) => (
        <React.Fragment key={index}>
          <Step
            state={
              currentStep === index
                ? StepState.Active
                : index < currentStep
                ? StepState.Passed
                : StepState.Inactive
            }
          >
            {index < currentStep ? <Svg icon={passedStep} /> : index + 1}
          </Step>
          {index + 1 < totalSteps && <StepDivider />}
        </React.Fragment>
      ))}
    </StyledStepper>
  )
}

export default Stepper
