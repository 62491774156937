import React from 'react'

import styled from 'src/styles'
import { Typography, TypographyView } from 'src/components/typography'
import { ButtonAppleLogin as ButtonAppleLoginBase } from 'src/components/button-apple-login'

export const ButtonAppleLogin = styled(ButtonAppleLoginBase)`
  margin-bottom: 12px;
`

export const TermsAndPrivacyMessage = styled(Typography).attrs({
  view: TypographyView.CaptionMedium,
})`
  color: ${(props) => props.theme.palette.label.secondary};

  margin-top: 14px;
  padding: 0 45px;

  text-align: center;
`

export const Link = styled.a`
  color: ${(props) => props.theme.palette.label.secondary};

  font-family: ${(props) => props.theme.typography.fontFamily};

  font-size: ${(props) => props.theme.typography.captionMedium.fontSize};
  font-weight: ${(props) => props.theme.typography.captionMedium.fontWeight};
  line-height: ${(props) => props.theme.typography.captionMedium.lineHeight};
  letter-spacing: ${(props) =>
    props.theme.typography.captionMedium.letterSpacing};

  text-decoration: underline;
`
