import BASE_ROUTES from 'src/router/constants'

export const AUTH = `${BASE_ROUTES.CHECKOUT_FLOW_APPLE_PAY}/auth`
export const PAYWALL = `${BASE_ROUTES.CHECKOUT_FLOW_APPLE_PAY}/paywall`
export const STORE_LINK = `${BASE_ROUTES.CHECKOUT_FLOW_APPLE_PAY}/store`

export const APPLE = `${BASE_ROUTES.CHECKOUT_FLOW_APPLE_PAY}/apple`

export const CHECKOUT_FLOW_APPLE_PAY = {
  AUTH,
  PAYWALL,
  STORE_LINK,
  APPLE
}

export default CHECKOUT_FLOW_APPLE_PAY
