import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import ServiceBase from 'src/api/services/base'
import { ICustomConfig } from 'src/api/types'

const defaultClientConfig = {
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'text/plain; charset=utf-8; image/jpeg;',
  },
}

export const httpClient = axios.create(defaultClientConfig)

class AxiosService extends ServiceBase {
  private httpClient = httpClient

  public async request<TResult = any>(
    config: AxiosRequestConfig,
    customConfig: ICustomConfig<TResult> = {}
  ) {
    let response: AxiosResponse<any> | undefined

    if (!config.baseURL) {
      config.baseURL = this.baseURL
    }

    const requestConfig = await this.requestMiddleware.reduce(
      (c, middleware) => middleware(c),
      config
    )

    response = await this.httpClient.request(requestConfig)

    this.responseMiddleware.reduce((r, middleware) => middleware(r), response)

    const mapResponse =
      customConfig.mapResponse || ((r: AxiosResponse) => r.data)

    return mapResponse(response)
  }
}

export default AxiosService
