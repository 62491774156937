import React from 'react'

import GoogleLogin from 'react-google-login'

import { GOOGLE_WEB_CLIENT_ID } from 'src/constants/authentication'
import { ButtonSize, ButtonView } from 'src/components/button'
import googleIcon from 'assets/icons/google.svg'

import { Button } from './styles'

export interface IButtonGoogleLoginProps {
  id?: string
  className?: string
  loading?: boolean
  onClick?: () => void
  onSubmit: (data: any) => void
}

export const ButtonGoogleLogin: React.FC<IButtonGoogleLoginProps> = ({
  id,
  className,
  onSubmit,
  loading,
  onClick
}: IButtonGoogleLoginProps) => {
  return (
    <GoogleLogin
      clientId={GOOGLE_WEB_CLIENT_ID}
      render={({ onClick: click, disabled }) => (
        <Button
          id={id}
          className={className}
          view={ButtonView.Secondary}
          size={ButtonSize.Large}
          disabled={disabled}
          startIcon={googleIcon}
          onClick={() => {
            if (onClick) {
              onClick()
            }
            click()
          }}
          isLoading={loading}
          fullWidth
        >
          <span className="body">
            Sign
            <span className="show-on-hover">&nbsp;|</span>
            <span className="hide-on-hover">&nbsp;In with Google</span>
          </span>
        </Button>
      )}
      onSuccess={onSubmit}
      accessType="accessType"
      responseType="code"
    />
  )
}

export default ButtonGoogleLogin
