import React from 'react'

import { IStoresMap } from 'src/store'
import connector from 'src/decorators/connector'
import { sendEvent } from 'src/utils/analytics'
import { EVENTS_AMPLITUDE } from 'src/constants/analytics/events'
import { AMPLITUDE } from 'src/constants/analytics'
import { TokenType } from 'src/constants/authentication'

import { ButtonGoogleLogin } from './styles'

export type GoogleFormProps = ReturnType<typeof storesToProps>

export interface IEmailFormState {
  loading: boolean
}

class GoogleForm extends React.Component<GoogleFormProps, IEmailFormState> {
  public constructor(props: GoogleFormProps) {
    super(props)

    this.state = {
      loading: false,
    }
  }

  public handleGoogleClick = () => {
    const { checkoutFlow } = this.props
    sendEvent(EVENTS_AMPLITUDE.RegisterButtonTap, [AMPLITUDE], {
      exp_name: checkoutFlow.name,
      register_option: TokenType.google
    })
  }

  public handleSubmit = async ({ code }: any = {}) => {
    try {
      this.setState({ loading: true })
      const { authentication, checkoutFlow } = this.props
      await authentication.authByGoogle(code)
      await checkoutFlow.afterAuthentication(
        authentication.email,
        authentication.allowEmailSend
      )
    } finally {
      this.setState({ loading: false })
    }
  }

  public render() {
    const { loading } = this.state
    return (
      <ButtonGoogleLogin
        id="prisma_checkout-flow_sign_btn-google-login"
        onSubmit={this.handleSubmit}
        loading={loading}
        onClick={this.handleGoogleClick}
      />
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  authentication: store.authentication,
  checkoutFlow: store.checkoutFlow,
})

export default connector(storesToProps)(GoogleForm)
