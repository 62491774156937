import { Instance, types } from 'mobx-state-tree'

import { Session } from './session'

import { Authentication } from './authentication'
import { Subscription } from './subscription'

import { CheckoutFlow } from '../modules/checkout-flow/store'
import { HiddenCheckoutFlow } from '../modules/checkout-flow/store'
import { CheckoutFlowApplePay } from '../modules/checkout-flow-apple-pay/store'

const Store = types
  .model({
    session: types.optional(Session, {}),

    authentication: types.optional(Authentication, {}),
    subscription: types.optional(Subscription, {}),

    checkoutFlow: types.optional(CheckoutFlow, {}),
    hiddenCheckoutFlow: types.optional(HiddenCheckoutFlow, {}),
    checkoutFlowApplePay: types.optional(CheckoutFlowApplePay, {}),
  })

export default Store

export interface IStore extends Instance<typeof Store> {}

export interface IStoresMap {
  store: IStore
}

