import React from 'react'

import { RouteComponentProps, withRouter } from 'react-router-dom'

import { AMPLITUDE, FACEBOOK } from 'src/constants/analytics'
import { sendEvent } from 'src/utils/analytics'
import { EVENTS_AMPLITUDE, EVENTS_FACEBOOK } from 'src/constants/analytics/events'
import { STRIPE_ENTITY_CONFIGURATION } from 'src/constants/subscription/config'
import { IStoresMap } from 'src/store'
import connector from 'src/decorators/connector'

import { getPriceValue } from '../../../checkout-flow/containers/store-block/utils'

import { PRISMA_LINK } from '../../constants/store'
import { DEFAULT_EXPERIMENT } from '../../constants/experiment'

import { Content, Button, Description, Title, AppLogo } from './style'

type ICheckoutFlowApplePayStoreLinkProps = RouteComponentProps & ReturnType<typeof storesToProps>

class CheckoutFlowApplePayStoreLink extends React.Component<ICheckoutFlowApplePayStoreLinkProps> {
  public componentDidMount() {
    const { location, checkoutFlowApplePay } = this.props
    checkoutFlowApplePay.initFromURL(location.search, DEFAULT_EXPERIMENT)

    sendEvent(EVENTS_AMPLITUDE.StoreLinkShow, [AMPLITUDE], {
      exp_name: checkoutFlowApplePay.name
    })

    // indicates, that we successfully paid subscription on Stripe
    if (this.props.location.hash === '#success') {
      sendEvent(
        EVENTS_FACEBOOK.SubscriptionPurchaseSuccess,
        [FACEBOOK], {
          currency: '$',
          value: getPriceValue(checkoutFlowApplePay.planConfig),
        })
      sendEvent(EVENTS_AMPLITUDE.SubscriptionPurchaseSuccess, [AMPLITUDE], {
          id: STRIPE_ENTITY_CONFIGURATION.PLAN,
          exp_name: checkoutFlowApplePay.name
        }
      )
    }
  }

  public handleAppClick = () => {
    const { checkoutFlowApplePay } = this.props
    sendEvent(EVENTS_FACEBOOK.StoreLinkTap, [FACEBOOK])
    sendEvent(EVENTS_AMPLITUDE.StoreLinkTap, [AMPLITUDE], {
      exp_name: checkoutFlowApplePay.name
    })
    document.location.href = PRISMA_LINK
  }

  public render() {
    return (
      <Content>
        <AppLogo/>

        <Title>Get Your App Now</Title>

        <Description>
          Use native mobile app <br/> for perfect experience
        </Description>

        <Button onClick={this.handleAppClick}>Get the App</Button>
      </Content>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  checkoutFlowApplePay: store.checkoutFlowApplePay,
})

export default withRouter(connector(storesToProps)(CheckoutFlowApplePayStoreLink))
