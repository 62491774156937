import { STRIPE_ENTITY_CONFIGURATION } from 'src/constants/subscription/config'
import { TrialType } from 'src/constants/subscription/trial'
import { ISubPlan } from 'src/models/sub-plan'

// not sure, that we need it
export const getPriceValue = (priceConfig: ISubPlan) => {
  return priceConfig.trialType === TrialType.free
    ? 0
    : priceConfig.trialType === TrialType.paid
      ? 1
      : priceConfig.coupon === STRIPE_ENTITY_CONFIGURATION.COUPON_50
        ? 14.99
        : priceConfig.coupon === STRIPE_ENTITY_CONFIGURATION.COUPON_70
          ? 9
          : 29.99
}
