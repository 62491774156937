import React, { Component } from 'react'

import { RouteComponentProps, withRouter } from 'react-router-dom'
import TagManager from 'react-gtm-module'

import { IStoresMap } from 'src/store'
import connector from 'src/decorators/connector'
import { getURLParamsAfterAppleAuth } from 'src/utils/authentication'
import { GMT_IDS } from 'src/constants/analytics'

import RegistrationBlock from './registration-block'
import ConfirmationBlock from './confimation-block'
import StoreBlock from './store-block'

import CHECKOUT_FLOW_ROUTES from '../constants/routes'
import { CheckoutFlowStep } from '../constants/page'
import { DEFAULT_EXPERIMENT } from '../constants/experiment'

import { Wrapper } from './styles'

type CheckoutFlowPageProps = RouteComponentProps & ReturnType<typeof storesToProps>

class CheckoutFlowPage extends Component<CheckoutFlowPageProps> {
  public componentDidMount() {
    const {
      match: { path },
      location: { pathname, search }
    } = this.props

    // Was user redirected from apple?
    if (path === CHECKOUT_FLOW_ROUTES.APPLE) {
      this.handleApple()
      return
    }

    const { checkoutFlow } = this.props
    checkoutFlow.initFromURL(search, DEFAULT_EXPERIMENT)
    if (pathname.includes(CHECKOUT_FLOW_ROUTES.AUTH)) {
      checkoutFlow.saveToStorage()
    }

    // Was user redirected from stripe?
    checkoutFlow.setStep(path.includes(CHECKOUT_FLOW_ROUTES.STORE)
      ? CheckoutFlowStep.store
      : CheckoutFlowStep.registration
    )

    // Google Tag Analytics
    const gtmId = GMT_IDS[checkoutFlow.plan]
    if (gtmId) {
      TagManager.initialize({ gtmId })
    }
  }

  public handleApple = async () => {
    const params = getURLParamsAfterAppleAuth(this.props.location.search)
    if (!params) {
      return
    }

    const { authentication, checkoutFlow } = this.props
    checkoutFlow.initFromStorage(DEFAULT_EXPERIMENT)
    checkoutFlow.setStep(CheckoutFlowStep.registration)

    await authentication.authByApple(params)
    await checkoutFlow.afterAuthentication('', authentication.allowEmailSend)

    checkoutFlow.clearStorage()
  }

  public render() {
    const { checkoutFlow } = this.props
    return (
      <Wrapper>
        {checkoutFlow.step === CheckoutFlowStep.registration
          ? <RegistrationBlock/>
          : checkoutFlow.step === CheckoutFlowStep.confirmation
            ? <ConfirmationBlock/>
            : checkoutFlow.step === CheckoutFlowStep.store
              ? <StoreBlock/>
              : null}
      </Wrapper>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  authentication: store.authentication,
  checkoutFlow: store.checkoutFlow,
})

export default withRouter(connector(storesToProps)(CheckoutFlowPage))
