import ROUTES from 'src/router/constants'
import { SUB_PLAN_CONFIGURATION, SubPlan } from 'src/constants/subscription/plans'
import { ISubscriptionCreateSessionOptions } from 'src/api/sections/subscriptions/types'

export const preFillSubscriptionOptions = (
  options: ISubscriptionCreateSessionOptions,
) => {
  const localOptions = { ...options }

  if (!localOptions.successUrl) {
    localOptions.successUrl = ROUTES.APP
  }

  if (!localOptions.cancelUrl) {
    localOptions.cancelUrl = ROUTES.APP
  }

  if (!localOptions.plan) {
    localOptions.plan = SUB_PLAN_CONFIGURATION[SubPlan.default]
  }

  return localOptions
}
