import React from 'react'

import { TypographyProps } from './types'
import { StyledTypography } from './styles'

export const Typography: React.FC<TypographyProps> = ({
  className,
  view,
  children,
}: TypographyProps) => (
  <StyledTypography className={className} view={view}>
    {children}
  </StyledTypography>
)

export default Typography
