import styled from 'src/styles'
import { Button2 as ButtonBase } from 'src/components/button'

export const Button = styled(ButtonBase)`
  .body,
  svg {
    position: relative;
    left: 0;
    transition: all ease-in-out 200ms;
  }

  .show-on-hover {
    font-weight: 400;
    visibility: hidden;
    opacity: 0;
  }

  .hide-on-hover {
    position: relative;
    left: -10px;
    transition: all ease-in-out 200ms;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      .body,
      svg {
        left: 53px;
      }

      .show-on-hover {
        visibility: visible;
        opacity: 1;
      }

      .hide-on-hover {
        left: 0;
        visibility: hidden;
        opacity: 0;
      }
    }
  }
`
