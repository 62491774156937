import React from 'react'

import { RouteComponentProps, withRouter } from 'react-router-dom'

import { IStoresMap } from 'src/store'
import connector from 'src/decorators/connector'
import Svg from 'src/components/svg'
import history from 'src/router/history'
import { ButtonAppleLogin } from 'src/components/button-apple-login'
import { getURLParamsAfterAppleAuth } from 'src/utils/authentication'
import { AMPLITUDE, FACEBOOK } from 'src/constants/analytics'
import { EVENTS_AMPLITUDE, EVENTS_FACEBOOK } from 'src/constants/analytics/events'
import { sendEvent } from 'src/utils/analytics'
import { TERMS_OF_USE_LINK, WEB_PRIVACY_LINK } from 'src/constants/privacy'

import appleSvg from 'assets/icons/apple.svg'

import CHECKOUT_FLOW_APPLE_PAY from '../../constants/routes'
import { DEFAULT_EXPERIMENT } from '../../constants/experiment'

import {
  AwardsLogo,
  Background,
  ButtonView,
  Comment,
  Content,
  InviteMessage,
  Link,
  Message,
  PrivacyTerms,
  Stars,
  Wrapper,
  BackgroundGradient
} from './style'

type ICheckoutFlowApplePayAuthProps = RouteComponentProps &
  ReturnType<typeof storesToProps>

class CheckoutFlowApplePayAuth extends React.Component<ICheckoutFlowApplePayAuthProps> {
  public async componentDidMount() {
    const { match } = this.props
    if (match.path === CHECKOUT_FLOW_APPLE_PAY.APPLE) {
      await this.handleAppleAuth()
    } else {
      this.handleFirstPageOpening()
    }
  }

  public handleFirstPageOpening = () => {
    const { location } = this.props

    const { checkoutFlowApplePay } = this.props
    checkoutFlowApplePay.initFromURL(location.search, DEFAULT_EXPERIMENT)
    checkoutFlowApplePay.saveToStorage()

    const params = new URLSearchParams(location.search)
    sendEvent(EVENTS_AMPLITUDE.RegisterShow, [AMPLITUDE], {
      exp_name: checkoutFlowApplePay.name,
      is_redirected: params.get('is_redirected') === 'true'
    })
  }

  public handleAppleAuth = async () => {
    const params = getURLParamsAfterAppleAuth(this.props.location.search)
    if (!params) {
      return
    }

    const { authentication, checkoutFlowApplePay } = this.props
    checkoutFlowApplePay.initFromStorage(DEFAULT_EXPERIMENT)
    await authentication.authByApple(params)

    sendEvent(EVENTS_FACEBOOK.RegisterSuccess, [FACEBOOK])
    sendEvent(EVENTS_AMPLITUDE.RegisterSuccess, [AMPLITUDE], {
      exp_name: checkoutFlowApplePay.name
    })

    history.push(`${CHECKOUT_FLOW_APPLE_PAY.PAYWALL}${checkoutFlowApplePay.queryParams}`)
    checkoutFlowApplePay.clearStorage()
  }

  public handleClick = () => {
    const { checkoutFlowApplePay } = this.props
    sendEvent(EVENTS_AMPLITUDE.RegisterButtonTap, [AMPLITUDE], {
      exp_name: checkoutFlowApplePay.name
    })
  }

  public render() {
    return (
      <Wrapper>
        <Background/>
        <BackgroundGradient/>
        <Content>
          <AwardsLogo/>

          <Comment>
            <Stars/>
            <Message>
              &quot;Prisma will make you fall in love <br/> with photo filters all
              over again&quot;
            </Message>
          </Comment>

          <Comment>
            <Stars/>
            <Message>
              &quot;I love using Prisma to jazz up my <br/> iPhone photos&quot;
            </Message>
          </Comment>

          <InviteMessage>Start Creating Your Own Art</InviteMessage>

          <ButtonAppleLogin
            redirectUrl="/auth/apple/checkout-apple-pay"
            onClick={this.handleClick}
          >
            <ButtonView>
              <Svg icon={appleSvg}/>
              <span>Sign up with Apple</span>
            </ButtonView>
          </ButtonAppleLogin>

          <PrivacyTerms>
            By Signing Up, you are agreeing to Prisma <br/>
            <Link target="blank" href={TERMS_OF_USE_LINK}>Terms of Use</Link> &amp;{' '}
            <Link target="blank" href={WEB_PRIVACY_LINK}>Privacy Policy</Link>
          </PrivacyTerms>
        </Content>
      </Wrapper>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  authentication: store.authentication,
  checkoutFlowApplePay: store.checkoutFlowApplePay
})

export default withRouter(connector(storesToProps)(CheckoutFlowApplePayAuth))
