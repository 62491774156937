import React from 'react'

import { IStoresMap } from 'src/store'
import connector from 'src/decorators/connector'
import { Button2, ButtonSize, ButtonView } from 'src/components/button'
import Stepper from 'src/components/stepper'
import Svg from 'src/components/svg'

import logo from 'assets/images/app-identity/prisma_logo.svg'

import { CheckoutFlowStep } from '../../constants/page'
import { Divider, LogoWrapper, BlockBody, BlockTitle, BlockWrapper, } from '../styles'

import Form from './form'

type ConfirmationBlockProps = ReturnType<typeof storesToProps>

class ConfirmationBlock extends React.Component<ConfirmationBlockProps> {
  public handleRestart = () => {
    const { authentication, checkoutFlow } = this.props
    checkoutFlow.setStep(CheckoutFlowStep.registration)
    authentication.restartFlow()
  }

  public render() {
    return (
      <BlockWrapper>
        <BlockTitle>
          <Stepper currentStep={1} totalSteps={3}/>
        </BlockTitle>
        <BlockBody>
          <LogoWrapper>
            <Svg icon={logo}/>
          </LogoWrapper>

          <Form/>

          <Divider>or</Divider>

          <Button2
            id="prisma_checkout-flow_confirm_btn-try-again"
            view={ButtonView.Secondary}
            size={ButtonSize.Large}
            onClick={this.handleRestart}
            fullWidth
          >
            Didn't get the code? Try again
          </Button2>
        </BlockBody>
      </BlockWrapper>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  checkoutFlow: store.checkoutFlow,
  authentication: store.authentication,
})

export default connector(storesToProps)(ConfirmationBlock)
