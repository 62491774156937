import styled from 'src/styles'

export const Wrapper = styled.div`
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  color: #ffffff;
  background-color: #000;

  display: flex;
  justify-content: center;
`
