import styled from 'src/styles'

import Svg from 'src/components/svg'
import { BaseButton } from 'src/components/button/styles'

import awards from 'assets/images/checkout-flow-apple-pay/awards.png'
import bg from 'assets/images/checkout-flow-apple-pay/bg_slide_3.jpg'
import starsSvg from 'assets/images/checkout-flow-apple-pay/stars.svg'

export const Wrapper = styled.div`
  position: relative;
  width: 700px;
  padding: 15px 20px 15px;

  display: flex;
  justify-content: center;
`

export const Background = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  background-image: url(${bg});
  background-position: 50% 0;
  background-size: cover;
  background-repeat: no-repeat;
`

export const BackgroundGradient = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  background-image: linear-gradient(177.57deg, rgba(0, 0, 0, 0.83) 23.82%, rgba(0, 0, 0, 0.083) 35.13%, rgba(0, 0, 0, 0.83) 47.35%);
`

export const Content = styled.div`
  position: relative;

  width: 255px;

  display: flex;
  justify-content: flex-end;
  flex-direction: column;
`

export const AwardsLogo = styled.img.attrs({
  src: awards,
  alt: '',
})`
  width: 100%;
`

export const Comment = styled.div`
  text-align: center;
  margin-bottom: 25px;
  
  @media (max-height: 600px) {
    margin-bottom: 15px;
  }
`

export const Stars = styled(Svg).attrs({
  icon: starsSvg,
})`
  margin-bottom: 15px;
  
  @media (max-height: 600px) {
     margin-bottom: 10px;
  }
`

export const Message = styled.div`
  font-size: 13px;
`

export const InviteMessage = styled.div`
  font-size: 32px;
  margin-bottom: 20px;
  
  font-weight: bold;
  line-height: 108%;
  text-align: center;
  
  @media (max-height: 600px) {
    font-size: 30px;
    margin-bottom: 15px;
  }
`

export const ButtonView = styled(BaseButton)`
  height: 58px;
  margin-bottom: 12px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #000;
  background-color: #ffffff;

  border-radius: 29px;

  font-weight: bold;
  font-size: 17px;
  
  & > svg {
    margin-right: 2px;
    margin-bottom: 5px;
  }
`

export const PrivacyTerms = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 11px;

  color: rgba(255, 255, 255, 0.5);
`

export const Link = styled.a`
  color: hsla(0, 0%, 100%, 0.7);
`
