import React from 'react'

import MobileDetect from 'mobile-detect'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { IStoresMap } from 'src/store'
import { TypographyView } from 'src/components/typography'
import Svg from 'src/components/svg'
import Stepper from 'src/components/stepper'
import connector from 'src/decorators/connector'
import { sendEvent } from 'src/utils/analytics'
import { STORE_LINKS } from 'src/constants/links'
import { Platform } from 'src/constants/platforms'
import { AMPLITUDE, FACEBOOK } from 'src/constants/analytics'
import { EVENTS_AMPLITUDE, EVENTS_FACEBOOK } from 'src/constants/analytics/events'

import blackHeart from 'assets/images/checkout-flow/black_heart.svg'
import appStore from 'assets/images/stores/app_store_dark.svg'
import googlePlay from 'assets/images/stores/google_play_dark.svg'

import { CHECKOUT_FLOW_STORE_LINKS } from '../../constants/links'
import { BlockWrapper, BlockBody, BlockTitle } from '../styles'

import { HeartWrapper, Message, StoreWrapper, ThankYouMessage } from './styles'

import { getPriceValue } from './utils'

type StoreBlockProps = RouteComponentProps & ReturnType<typeof storesToProps>

class StoreBlock extends React.Component<StoreBlockProps> {
  public componentDidMount() {
    const { location, checkoutFlow } = this.props

    sendEvent(EVENTS_AMPLITUDE.StoreLinkShow, [AMPLITUDE], {
      exp_name: checkoutFlow.name
    })

    // indicates, that we successfully bought subscription on Stripe
    if (location.hash === '#success') {
      sendEvent(
        EVENTS_FACEBOOK.SubscriptionPurchaseSuccess,
        [FACEBOOK], {
          currency: '$',
          value: getPriceValue(checkoutFlow.planConfig),
        })
      sendEvent(
        EVENTS_AMPLITUDE.SubscriptionPurchaseSuccess,
        [AMPLITUDE], {
          exp_name: checkoutFlow.name
        }
      )
    }
  }

  public get md() {
    return new MobileDetect(window.navigator.userAgent)
  }

  public handleAppClick = () => {
    const { checkoutFlow } = this.props

    sendEvent(EVENTS_FACEBOOK.StoreLinkTap, [FACEBOOK])
    sendEvent(EVENTS_AMPLITUDE.StoreLinkTap, [AMPLITUDE], {
      exp_name: checkoutFlow.name
    })

    let href = CHECKOUT_FLOW_STORE_LINKS[checkoutFlow.planConfig.trialType]
    if (!href) {
      href =
        STORE_LINKS[this.md.os() === 'iOS' ? Platform.ios : Platform.android]
    }

    document.location.href = href
  }

  public render() {
    return (
      <BlockWrapper>
        <BlockTitle>
          <Stepper currentStep={3} totalSteps={3}/>
        </BlockTitle>
        <BlockBody>
          <HeartWrapper>
            <Svg icon={blackHeart}/>
          </HeartWrapper>

          <ThankYouMessage view={TypographyView.Title2}>
            Thank You!
          </ThankYouMessage>
          <Message view={TypographyView.BodyMedium}>
            Enjoy your Prisma Premium experience! Download the app and log in
            with the same account
          </Message>

          <StoreWrapper>
            <Svg
              id="prisma_checkout-flow_end_btn-store"
              icon={this.md.os() === 'iOS' ? appStore : googlePlay}
              onClick={this.handleAppClick}
            />
          </StoreWrapper>
        </BlockBody>
      </BlockWrapper>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  checkoutFlow: store.checkoutFlow,
})

export default withRouter(connector(storesToProps)(StoreBlock))
