import React, { Component } from 'react'
// import { GOOGLE_ANALYTICS_ID } from 'src/constants/analytics'

class GoogleAnalytics extends Component {
  // @todo uncomment
  // public componentDidMount() {
  //   window.dataLayer = window.dataLayer || []
  //   // tslint:disable-next-line:only-arrow-functions
  //   window.gtag = function () {
  //     dataLayer.push(arguments)
  //   }
  //
  //   gtag('js', new Date())
  //   gtag('config', GOOGLE_ANALYTICS_ID)
  // }

  public render() {
    return <></>
  }
}

export default GoogleAnalytics
