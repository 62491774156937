import styled from 'src/styles'

import Svg from 'src/components/svg'

import appLogoSvg from 'assets/images/checkout-flow-apple-pay/prisma_app_icon.svg'

import { BaseButton } from 'src/components/button/styles'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const AppLogo = styled(Svg).attrs({
  icon: appLogoSvg,
})`
  margin-bottom: 26px;
`

export const Title = styled.div`
  font-weight: bold;
  font-size: 32px;

  margin-bottom: 20px;
`

export const Description = styled.div`
  color: rgba(255, 255, 255, 0.7);
  font-size: 18px;

  margin-bottom: 50px;
`

export const Button = styled(BaseButton)`
  width: 176px;
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #000;
  background-color: #fed700;

  border-radius: 12px;

  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
`
