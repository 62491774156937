import styled from 'src/styles'

import { Typography, TypographyView } from 'src/components/typography'

export const Wrapper = styled.div``

export const BlockWrapper = styled.div`
  width: 320px;
  
  background-color: ${(props) => props.theme.palette.background.elevated};
  box-shadow: ${(props) => props.theme.shadows.float};
  border-radius: 16px;
  
  overflow: hidden;
`

export const BlockBody = styled.div`
  padding: 24px 16px 16px 16px;
`

export const BlockTitle = styled.div`
  display: flex;
  justify-content: center;

  padding: 16px;

  border-bottom: 1px solid ${(props) => props.theme.palette.fill.secondary};
`

export const LogoWrapper = styled.div`
  width: 100%;
  margin: 10px 0 24px 0;
  display: flex;
  justify-content: center;
`

export const SubTitle = styled(Typography).attrs({
  view: TypographyView.BodyMedium,
})`
  color: ${(props) => props.theme.palette.label.primary};

  margin-bottom: 24px;
  padding: 0 20px;

  text-align: center;
`

export const InputWrapper = styled.div`
  display: flex;
  margin-bottom: 12px;
`

export const Divider = styled.div`
  margin: 12px 0;

  color: ${(props) => props.theme.palette.label.secondary};

  font-size: ${(props) => props.theme.typography.bodyRegular.fontSize};
  font-weight: ${(props) => props.theme.typography.bodyRegular.fontWeight};
  line-height: ${(props) => props.theme.typography.bodyRegular.lineHeight};
  letter-spacing: ${(props) =>
    props.theme.typography.bodyRegular.letterSpacing};

  display: flex;
  justify-content: center;
  align-items: center;

  &:before,
  &:after {
    background-color: ${(props) => props.theme.palette.fill.primary};

    margin: 0 10px;
    width: 126px;
    height: 1px;

    content: '';
  }

  &:before {
    margin-left: 0;
  }

  &:after {
    margin-right: 0;
  }
`
