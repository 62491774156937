import React from 'react'
import ReactDOM from 'react-dom'

import App from 'src/components/app'

import { createStore } from 'src/store/instance'

export default () => {
  const store = createStore()

  // @ts-ignore
  if (module.hot) {
    // @ts-ignore
    window.__store = store
  }

  ReactDOM.render(<App/>, document.getElementById('root'))
}
