// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/fonts/cofo-sans/CoFo_Sans-Bold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("assets/fonts/cofo-sans/CoFo_Sans-Medium.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("assets/fonts/cofo-sans/CoFo_Sans-Regular.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n    font-family: 'CoFo Sans';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff');\n    font-weight: 700;\n}\n\n@font-face {\n    font-family: 'CoFo Sans';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff');\n    font-weight: 500;\n}\n\n@font-face {\n    font-family: 'CoFo Sans';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('woff');\n    font-weight: 300;\n}\n", "",{"version":3,"sources":["webpack://./assets/css/font.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,2DAAqE;IACrE,gBAAgB;AACpB;;AAEA;IACI,wBAAwB;IACxB,2DAAuE;IACvE,gBAAgB;AACpB;;AAEA;IACI,wBAAwB;IACxB,2DAAwE;IACxE,gBAAgB;AACpB","sourcesContent":["@font-face {\n    font-family: 'CoFo Sans';\n    src: url('assets/fonts/cofo-sans/CoFo_Sans-Bold.woff') format('woff');\n    font-weight: 700;\n}\n\n@font-face {\n    font-family: 'CoFo Sans';\n    src: url('assets/fonts/cofo-sans/CoFo_Sans-Medium.woff') format('woff');\n    font-weight: 500;\n}\n\n@font-face {\n    font-family: 'CoFo Sans';\n    src: url('assets/fonts/cofo-sans/CoFo_Sans-Regular.woff') format('woff');\n    font-weight: 300;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
