import { Instance, types } from 'mobx-state-tree'

import StorageItem from 'src/models/storage-item'

import { NO_NAME_EXP } from 'src/constants/experiment'
import { SUB_PLAN_CONFIGURATION, SubPlan } from 'src/constants/subscription/plans'

export interface IExperimentInfo {
  name: string
  plan: SubPlan
}

export const experimentStorage = new StorageItem<IExperimentInfo>(localStorage, 'experiment:state')

export const Experiment = types
  .model('Experiment', {
    name: types.optional(types.string, NO_NAME_EXP),
    plan: types.optional(
      types.enumeration('Plan', [...Object.values(SubPlan)]),
      SubPlan.default
    ),
  })
  .views(self => ({
    get planConfig() {
      return SUB_PLAN_CONFIGURATION[self.plan]
    },
    get queryParams() {
      return `?plan=${self.plan}&exp_name=${self.name}`
    }
  }))
  .actions(self => ({
    init(exp: IExperimentInfo) {
      self.name = exp.name
      self.plan = exp.plan
    }
  }))
  .actions(self => ({
    initFromURL(search: string, defaultExperiment: IExperimentInfo) {
      const params = new URLSearchParams(search || '')

      let plan: SubPlan = params.get('plan') as SubPlan
      if (!Object.values(SubPlan).includes(plan)) {
        plan = defaultExperiment.plan
      }

      self.init({
        name: params.get('exp_name') || defaultExperiment.name,
        plan,
      })
    },
    initFromStorage(defaultExperiment: IExperimentInfo) {
      self.init(experimentStorage.getParsed() || defaultExperiment)
    }
  }))
  .actions(self => ({
    saveToStorage() {
      experimentStorage.stringifyAndSet({
        name: self.name,
        plan: self.plan,
      })
    }
  }))
  .actions(() => ({
    clearStorage() {
      experimentStorage.clear()
    }
  }))

export interface IExperiment extends Instance<typeof Experiment> {}
