import styled from 'src/styles'
import { Typography } from 'src/components/typography'

export const HeartWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  margin-top: 15px;
  margin-bottom: 8px;
`

export const ThankYouMessage = styled(Typography)`
  text-align: center;
  margin-bottom: 8px;
`

export const Message = styled(Typography)`
  text-align: center;
  margin-bottom: 32px;
`

export const StoreWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  margin-bottom: 32px;
`
