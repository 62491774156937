import React from 'react'

import { StyledLoader } from './styles'
import { LoaderProps } from './types'

const Loader: React.FC<LoaderProps> = ({ className, size }) => {
  return <StyledLoader className={className} size={size} />
}

export default Loader
