import React from 'react'

import { IStoresMap } from 'src/store'
import connector from 'src/decorators/connector'
import Stepper from 'src/components/stepper'
import Svg from 'src/components/svg'

import { sendEvent } from 'src/utils/analytics'
import { EVENTS_AMPLITUDE } from 'src/constants/analytics/events'
import { AMPLITUDE } from 'src/constants/analytics'
import { TokenType } from 'src/constants/authentication'
import { PRIVACY_POLICY_LINK, TERMS_OF_USE_LINK } from 'src/constants/privacy'

import logo from 'assets/images/app-identity/prisma_logo.svg'

import {
  BlockWrapper,
  Divider,
  LogoWrapper,
  BlockBody,
  BlockTitle,
  SubTitle,
} from '../styles'

import Checkmark from './checkmark'
import GoogleForm from './google-form'
import EmailForm from './email-form'

import { ButtonAppleLogin, Link, TermsAndPrivacyMessage } from './styles'

type RegistrationBlockProps = ReturnType<typeof storesToProps>

class RegistrationBlock extends React.Component<RegistrationBlockProps, {}> {
  public componentDidMount() {
    const { checkoutFlow } = this.props
    sendEvent(EVENTS_AMPLITUDE.RegisterShow, [AMPLITUDE], {
      exp_name: checkoutFlow.name
    })
  }

  public handleAppleClick = () => {
    const { checkoutFlow } = this.props
    sendEvent(EVENTS_AMPLITUDE.RegisterButtonTap, [AMPLITUDE], {
      exp_name: checkoutFlow.name,
      register_option: TokenType.apple
    })
  }

  public render() {
    const { authentication } = this.props
    return (
      <BlockWrapper>
        <BlockTitle>
          <Stepper currentStep={1} totalSteps={3}/>
        </BlockTitle>
        <BlockBody>
          <LogoWrapper>
            <Svg icon={logo}/>
          </LogoWrapper>

          <SubTitle>Enter your email to sign in or create an account</SubTitle>

          <EmailForm/>

          <Divider>or</Divider>

          <ButtonAppleLogin
            id="prisma_checkout-flow_sign_btn-apple-login"
            loading={authentication.loadingApple}
            redirectUrl="/auth/apple/checkout"
            onClick={this.handleAppleClick}
          />
          <GoogleForm/>

          <Checkmark
            checked={authentication.allowEmailSend}
            onClick={() =>
              authentication.setAllowEmailSend(!authentication.allowEmailSend)
            }
          >
            Get emails with tutorials & updates
          </Checkmark>

          <TermsAndPrivacyMessage>
            By signing up you are agreeing to our &nbsp;
            <Link target="blank" href={TERMS_OF_USE_LINK}>
              Terms of Use
            </Link>
            &nbsp;&&nbsp;
            <Link target="blank" href={PRIVACY_POLICY_LINK}>
              Privacy Policy
            </Link>
            .
          </TermsAndPrivacyMessage>
        </BlockBody>
      </BlockWrapper>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  authentication: store.authentication,
  checkoutFlow: store.checkoutFlow
})

export default connector(storesToProps)(RegistrationBlock)
