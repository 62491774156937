class StorageItem<T = any> {
  protected storage: Storage
  protected key: string

  constructor(storage: Storage, key: string) {
    this.storage = storage
    this.key = key
  }

  public get() {
    return this.storage.getItem(this.key)
  }

  public getParsed(reviver?: (key: any, value: any) => any) {
    return JSON.parse(this.get() || 'null', reviver)
  }

  public set<TValue extends T & (string | boolean | number | null)>(
    value: TValue
  ) {
    this.storage.setItem(this.key, value as any)
  }

  public stringifyAndSet(
    value: T,
    replacer?: (key: string, value: any) => any,
    space?: string | number
  ) {
    this.storage.setItem(this.key, JSON.stringify(value, replacer, space))
  }

  public clear() {
    this.storage.removeItem(this.key)
  }
}

export default StorageItem
