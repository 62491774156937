import { Environment } from '../environment'

const STRIPE_ENTITIES_CONFIGURATIONS = {
  [Environment.prod]: {
    STRIPE_KEY: 'pk_live_Id6DiZ1OJHW4IKEgkVKRFtXa00bMr86Xzh',
    PLAN: 'price_1IqFMFClNwQVdAgY6Rk5UeKr', // 29.99/yearly
    PLAN1$: 'price_1IlsjoClNwQVdAgYXxshuxkG', // 1$
    COUPON_50: 'e4K4lwPb',
    COUPON_70: 'OuCv1cIF',
  },
  [Environment.stage]: {
    STRIPE_KEY: 'pk_test_SfjL3znhIgc9q9Srn1oxeSNA00u9X66wFF',
    PLAN: 'plan_FvHNSrCTAc06Ek',
    PLAN1$: 'price_1IlvUeClNwQVdAgYnSokRxc0',
    COUPON_50: 'rvwbn8t9',
    COUPON_70: 'nOK0jbaL',
  },
  [Environment.dev]: {
    STRIPE_KEY: 'pk_test_SfjL3znhIgc9q9Srn1oxeSNA00u9X66wFF',
    PLAN: 'plan_FvHNSrCTAc06Ek',
    PLAN1$: 'price_1IlvUeClNwQVdAgYnSokRxc0',
    COUPON_50: 'rvwbn8t9',
    COUPON_70: 'nOK0jbaL',
  }
}

export const STRIPE_ENTITY_CONFIGURATION = STRIPE_ENTITIES_CONFIGURATIONS[ENV]
