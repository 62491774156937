import React from 'react'

import { IStoresMap } from 'src/store'
import connector from 'src/decorators/connector'
import { Input } from 'src/components/input'
import { Button2, ButtonSize, ButtonView } from 'src/components/button'
import { ComponentSize } from 'src/constants/web-kit/sizes'
import { validateEmail } from 'src/utils/email'
import { sendEvent } from 'src/utils/analytics'
import { EVENTS_AMPLITUDE } from 'src/constants/analytics/events'
import { AMPLITUDE } from 'src/constants/analytics'
import { TokenType } from 'src/constants/authentication'

import mailIcon from 'assets/icons/mail.svg'

import { CheckoutFlowStep } from '../../../constants/page'
import { InputWrapper } from '../../styles'

export const EMAIL_ERRORS = {
  EmptyEmail: 'Enter an email address',
  IncorrectEmail: 'Incorrect email address',
}

export type EmailFormProps = ReturnType<typeof storesToProps>

export interface IEmailFormState {
  email: string
  loading: boolean
}

class EmailForm extends React.Component<EmailFormProps, IEmailFormState> {
  public constructor(props: EmailFormProps) {
    super(props)

    this.state = {
      email: '',
      loading: false,
    }
  }

  public handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: e.target.value })
  }

  public isEmailValid = (email: string): string => {
    if (email.length === 0) {
      return EMAIL_ERRORS.EmptyEmail
    }
    return validateEmail(email) ? '' : EMAIL_ERRORS.IncorrectEmail
  }

  public handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault()

    const { authentication, checkoutFlow } = this.props

    sendEvent(EVENTS_AMPLITUDE.RegisterButtonTap, [AMPLITUDE], {
      exp_name: checkoutFlow.name,
      register_option: TokenType.email
    })

    authentication.setError('')

    // Validation
    const { email } = this.state
    const error = this.isEmailValid(email)
    if (error) {
      authentication.setError(error)
      return
    }

    // Authorization
    try {
      this.setState({ loading: true })
      await authentication.authByEmail(email.toLowerCase())
      checkoutFlow.setStep(CheckoutFlowStep.confirmation)
    } finally {
      this.setState({ loading: false })
    }
  }

  public render() {
    const { authentication } = this.props
    const { email, loading } = this.state

    return (
      <form onSubmit={this.handleSubmit}>
        <InputWrapper>
          <Input
            placeholder="Your email"
            value={email}
            onChange={this.handleEmailChange}
            startIcon={mailIcon}
            error={authentication.error}
            componentSize={ComponentSize.Large}
            showError
            fullWidth
          />
        </InputWrapper>

        <Button2
          id="prisma_checkout-flow_sign_btn-continue"
          view={ButtonView.CTA}
          size={ButtonSize.Large}
          isLoading={loading}
          fullWidth
        >
          Continue
        </Button2>
      </form>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  authentication: store.authentication,
  checkoutFlow: store.checkoutFlow,
})

export default connector(storesToProps)(EmailForm)
