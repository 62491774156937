import styled from 'src/styles'

export const Wrapper = styled.div`
  .container {
    width: 100%;
    height: 48px;
  }

  .character {
    width: 44px;

    padding: 12px 14px;
    margin-right: 5px;

    color: ${(props) => props.theme.palette.label.primary};
    background-color: ${(props) => props.theme.palette.fill.tertiary};
    border: 1px solid rgba(0, 0, 0, 0);

    border-radius: 12px;

    font-family: ${(props) => props.theme.typography.fontFamily};
    font-size: ${(props) => props.theme.typography.title3.fontSize};
    font-weight: ${(props) => props.theme.typography.title3.fontWeight};
    line-height: ${(props) => props.theme.typography.title3.lineHeight};
    letter-spacing: ${(props) => props.theme.typography.title3.letterSpacing};

    cursor: text;
  }

  .character--selected {
    color: ${(props) => props.theme.palette.label.primary};
    background-color: #fff;
    border: 1px solid ${(props) => props.theme.palette.highlight.base};
    box-shadow: 0 0 0 2px ${(props) => props.theme.palette.highlight.trail};
  }

  &.has-error {
    .character:last-child {
      color: ${(props) => props.theme.palette.label.primary};
      background-color: #fff;
      border: 1px solid ${(props) => props.theme.palette.error.base};
      box-shadow: 0 0 0 2px ${(props) => props.theme.palette.error.trail};
    }
  }
`
