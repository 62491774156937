import React from 'react'

import { inject, observer } from 'mobx-react'
import { IStoresToProps } from 'mobx-react/dist/types/IStoresToProps'

export default function connector<S, P, I, C>(
  mapStore: IStoresToProps<S, P, I, C>
) {
  return (Component: React.ComponentClass<I & P>) => {
    const Observer = observer(Component)

    @inject(mapStore)
    class Connector extends React.Component<I & P, {}> {
      public render() {
        return <Observer {...this.props} />
      }
    }

    // @ts-ignore
    return Connector as React.ComponentClass<P>
  }
}
