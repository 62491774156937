import { Environment } from 'src/constants/environment'

const CONFIGURATIONS = {
  [Environment.prod]: {
    API_URL: 'https://webapi.neuralprisma.com',
  },
  [Environment.stage]: {
    API_URL: 'https://webapi-stg.neuralprisma.com',
  },
  [Environment.dev]: {
    API_URL: 'https://webapi-stg.neuralprisma.com',
  }
}

export default CONFIGURATIONS[ENV]
