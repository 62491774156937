import React, { Component } from 'react'

import { Redirect, Route, RouteComponentProps, Switch, withRouter, } from 'react-router-dom'

import ROUTES from 'src/router/constants'

import CHECKOUT_FLOW_APPLE_PAY from '../constants/routes'

import CheckoutFlowApplePayAuth from '../containers/auth'
import CheckoutFlowApplePayPaywall from '../containers/paywall'
import CheckoutFlowApplePayStoreLink from '../containers/store-link'

import { Wrapper } from './styles'

type ICheckoutFlowApplePayRouterProps = RouteComponentProps

class CheckoutFlowApplePayRouter extends Component<ICheckoutFlowApplePayRouterProps> {
  public render() {
    return (
      <Wrapper>
        <Switch>
          {[CHECKOUT_FLOW_APPLE_PAY.AUTH, CHECKOUT_FLOW_APPLE_PAY.APPLE].map(route => (
            <Route
              key={route}
              exact
              path={route}
              component={CheckoutFlowApplePayAuth}
            />
          ))}

          <Route
            exact
            path={CHECKOUT_FLOW_APPLE_PAY.PAYWALL}
            component={CheckoutFlowApplePayPaywall}
          />

          <Route
            exact
            path={CHECKOUT_FLOW_APPLE_PAY.STORE_LINK}
            component={CheckoutFlowApplePayStoreLink}
          />

          <Redirect to={ROUTES.APP}/>
        </Switch>
      </Wrapper>
    )
  }
}

export default withRouter(CheckoutFlowApplePayRouter)
