import Store, { IStore } from 'src/store'
import createInstanceWrapper from 'src/utils/store'

const storeInstance = createInstanceWrapper<IStore>('Store')

export const createStore = () => {
  const store = Store.create()
  storeInstance.set(store)
  return store
}

export default storeInstance
