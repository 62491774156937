import styled from 'src/styles'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  -webkit-tap-highlight-color: transparent;

  & > svg {
    margin-right: 10px;
  }
`
