import styled from 'src/styles'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  max-width: 444px;
  height: 100vh;
  margin: 0 auto;
  padding: 20px 0 44px 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  background-color: ${(props) => props.theme.palette.background.base};
`

export const Link = styled.a`
  text-decoration: none;
`

export const AppIcon = styled.img`
  width: 76px;
  height: 74px;
`

export const Text = styled.div`
  font-size: 28px;
  font-weight: 700;
  line-height: 35px;
  color: #333;
  text-align: center;
`

export const Footer = styled.div`
  font-size: 11px;
  line-height: 16px;
  letter-spacing: -0.3px;
  color: rgba(60, 60, 67, 0.6);
  text-align: center;
`