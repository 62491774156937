// Base routes
const APP = '/'

// Landings list
const CHECKOUT_FLOW = '/checkout'
const CHECKOUT_FLOW_APPLE_PAY = '/checkout/apple-pay'
const CHECKOUT_HIDDEN = '/checkout/by-mail'
const UNAVAILABLE_OFFER = '/offer-expired'

export const ROUTES = {
  APP,
  CHECKOUT_FLOW,
  CHECKOUT_FLOW_APPLE_PAY,
  CHECKOUT_HIDDEN,
  UNAVAILABLE_OFFER
}

export default ROUTES
