import { Search } from 'history'

// User can be redirected from apple (if he used this auth method).
// In this case we try to get token, etc info from query params.
export const getURLParamsAfterAppleAuth = (search: Search) => {
  const params = new URLSearchParams(search)

  const token = params.get('token')
  const userId = params.get('user_id')
  const error = params.get('error')

  return (token && userId) || error
    ? {
        token,
        userId,
        error,
      }
    : null
}
