import React, { Component } from 'react'

import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom'

import ROUTES from 'src/router/constants'

import CheckoutFlowRouter from 'src/modules/checkout-flow/router'
import LandingPage from 'src/modules/landing'
import HiddenAuth from '../modules/hidden-auth'
import UnavailableOffer from '../modules/unavailable-offer'
import CheckoutFlowApplePayRouter from 'src/modules/checkout-flow-apple-pay/router'

class Router extends Component<RouteComponentProps> {
  public render() {
    return (
      <Switch>
        <Route exact path={ROUTES.APP} component={LandingPage} />
        <Route path={ROUTES.CHECKOUT_HIDDEN} component={HiddenAuth} />
        <Route path={ROUTES.UNAVAILABLE_OFFER} component={UnavailableOffer} />

        {/* Order of routes is important !!! */}
        <Route path={ROUTES.CHECKOUT_FLOW_APPLE_PAY}>
          <CheckoutFlowApplePayRouter/>
        </Route>

        <Route path={ROUTES.CHECKOUT_FLOW}>
          <CheckoutFlowRouter/>
        </Route>

        <Redirect to={ROUTES.APP}/>
      </Switch>
    )
  }
}

export default withRouter(Router)
