// @ts-nocheck
// tslint:disable only-arrow-functions no-unused-expression curly

import React, { Component } from 'react'

import { FACEBOOK_ANALYTICS_PIXEL_ID } from 'src/constants/analytics'

class FacebookAnalytics extends Component {
  public componentDidMount() {
    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments)
      }
      if (!f._fbq) f._fbq = n
      n.push = n
      n.loaded = !0
      n.version = '2.0'
      n.queue = []
      t = b.createElement(e)
      t.async = !0
      t.src = v
      s = b.getElementsByTagName(e)[0]
      s.parentNode.insertBefore(t, s)
    })(
      window,
      document,
      'script',
      'https://connect.facebook.net/en_US/fbevents.js'
    )

    fbq('init', FACEBOOK_ANALYTICS_PIXEL_ID)
    fbq('trackSingle', FACEBOOK_ANALYTICS_PIXEL_ID, 'PageView')
  }

  public render() {
    return (
      <noscript>
        <img
          height="1"
          width="1"
          style="display:none"
          src={`https://www.facebook.com/tr?id=${FACEBOOK_ANALYTICS_PIXEL_ID}&ev=PageView&noscript=1`}
        />
      </noscript>
    )
  }
}

export default FacebookAnalytics
