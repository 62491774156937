import storeInstance from 'src/store/instance'
import { RequestMiddleware, ResponseMiddleware } from './types'

export const requestMiddlewares: RequestMiddleware[] = [
  (request) => {
    const accessToken = storeInstance.get().session.token
    const userID = storeInstance.get().session.userID
    const app = storeInstance.get().session.app

    request.headers = {
      ...(userID ? { 'prisma-user-id': userID } : {}),
      ...(accessToken ? { 'prisma-user-token': accessToken } : {}),
      ...(app ? { 'prisma-app': app } : {}),
      ...request.headers,
    }
    return request
  },
]

export const responseMiddlewares: ResponseMiddleware[] = []
