import React from 'react'

// @ts-ignore
import VerificationInput from 'react-verification-input'

import { ErrorMessage } from 'src/components/input/styles'

import { Wrapper } from './styles'

export const CODE_LENGTH = 6

interface IInputCodeProps {
  code?: string
  error?: string
  onChange: (code: string) => void
}

class InputVerificationCode extends React.Component<IInputCodeProps, {}> {
  public render() {
    const { error } = this.props
    return (
      <Wrapper className={`${Boolean(error) ? 'has-error' : ''}`}>
        <ErrorMessage visible={Boolean(error)}> {error} </ErrorMessage>
        <VerificationInput
          classNames={{
            container: 'container',
            character: 'character',
            characterSelected: 'character--selected',
          }}
          placeholder=""
          onChange={this.props.onChange}
          inputProps={{ type: 'tel' }}
          validChars="0-9"
          removeDefaultStyles
          autoFocus
        />
      </Wrapper>
    )
  }
}

export default InputVerificationCode
