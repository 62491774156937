import styled from 'src/styles'

import { BaseButton } from 'src/components/button/styles'

import bg from 'assets/images/checkout-flow-apple-pay/bg_slide_4.jpg'

export const Wrapper = styled.div`
  position: relative;
  width: 700px;
  padding: 0 20px 53px;

  display: flex;
  justify-content: center;
`

export const Background = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  background-image: url(${bg});
  background-position: 50% 0;
  background-size: cover;
  background-repeat: no-repeat;
`

export const BackgroundGradient = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 46.88%, rgba(0, 0, 0, 0.56) 61.98%, rgba(0, 0, 0, 0.88) 100%);
`

export const Content = styled.div`
  position: relative;

  display: flex;
  justify-content: flex-end;
  flex-direction: column;
`

export const Offer = styled.div`
  max-width: 335px;
  text-align: center;
`

export const OfferTitle = styled.div`
  color: #fff;

  font-size: 34px;
  font-weight: bold;

  margin-bottom: 10px;
  
  & > span {
    color: #fed700;
  }
`

export const OfferDescription = styled.div`
  color: hsla(0, 0%, 100%, 0.7);

  font-size: 20px;

  margin-bottom: 24px;
`

export const Button = styled(BaseButton)`
  width: 100%;
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #000;
  background-color: #fed700;

  border-radius: 12px;

  font-weight: bold;
  font-size: 17px;
`
