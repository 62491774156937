import React, { Component } from 'react'
import { Wrapper, Link, AppIcon, Text, Footer } from './styles'
import logoSvg from '../../../assets/images/app-identity/logo-prisma.png'
import { MAIN_SITE } from 'src/constants/links'

class UnavailableOffer extends Component {
  public render() {
    return (
      <Wrapper>
        <Link
          href={MAIN_SITE}
          target='_blank'
        >
          <AppIcon src={logoSvg} width={152}/>
        </Link>

        <Text>Sorry, the offer is already unavailable</Text>

        <Footer>
          © 2019 - {new Date().getFullYear()} Prisma Labs, Inc.
        </Footer>
      </Wrapper>
    )
  }
}

export default UnavailableOffer
