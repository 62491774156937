import { flow, getRoot, Instance, types } from 'mobx-state-tree'

import API from 'src/api'
import { ISubscriptionCreateSessionOptions } from 'src/api/sections/subscriptions/types'
import { STRIPE_ENTITY_CONFIGURATION } from 'src/constants/subscription/config'

import { IStore } from '../index'

import { preFillSubscriptionOptions } from './utils'

const stripe = Stripe(STRIPE_ENTITY_CONFIGURATION.STRIPE_KEY)

export const Subscription = types
  .model('Subscription')
  .views(self => ({
    get rootStore(): IStore {
      return getRoot(self)
    }
  }))
  .actions((self) => ({
    purchase: flow(function* (options: ISubscriptionCreateSessionOptions) {
      try {
        const { session } = self.rootStore
        const opt = preFillSubscriptionOptions(options, session.tokenType)
        const { id } = yield API.subscription.createStripeSession(opt)
        stripe
          .redirectToCheckout({
            // Make the id field from the Checkout Session creation API response
            // available to this file, so you can provide it as parameter here
            // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
            sessionId: id,
          })
          .then((result: any) => {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
          })
      } catch (e) {
        throw e
      }
    }),
  }))

export interface ISubscription extends Instance<typeof Subscription> {}
