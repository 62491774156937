import React, { Component } from 'react'
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom'
import { IStoresMap } from 'src/store'
import connector from 'src/decorators/connector'
import { sessionStorage } from '../../store/session/actions/restore-token'
import { DEFAULT_EXPERIMENT } from '../checkout-flow/constants/experiment'

export type HiddenAuthProps = RouteComponentProps & ReturnType<typeof storesToProps>

class HiddenAuth extends Component<HiddenAuthProps, {}> {
  private getQueryParams() {
    const query = new URL(location.href)

    return {
      key: query.searchParams.get('key'),
      app: query.searchParams.get('app')
    }
  }

  private getQueryFulfill() {
    const { history } = this.props
    const {key, app} = this.getQueryParams()

    if (!key || !app) {
      history.push(`/offer-expired`);
      throw new Error('Access without user key and application params is unavailable. Redirecting.')
    }

    return { key, app }
  }


  private async auth() {
    const { session, authentication, hiddenCheckoutFlow, history } = this.props
    const { key, app } = this.getQueryFulfill()

    session.setApp(app)
    
    try {
      await authentication.hiddenAuth(key)
      await hiddenCheckoutFlow.afterAuthentication(authentication.email)  
    } catch (e) {
      if (e.response?.status === 422) {
        history.push(`/offer-expired`);
      }
    }
    
    sessionStorage.clear()
  }

  private async init() {
    const { hiddenCheckoutFlow, location: { search } } = this.props
    hiddenCheckoutFlow.initFromURL(search, DEFAULT_EXPERIMENT)
  }

  public async componentDidMount() {
    sessionStorage.clear()
    await this.init()
    await this.auth()
  }

  public render() {
    return (
      <></>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  authentication: store.authentication,
  hiddenCheckoutFlow: store.hiddenCheckoutFlow,
  session: store.session
})

export default withRouter(connector(storesToProps)(HiddenAuth))
