import { SubPlan } from '../subscription/plans'

// Sources
export const GOOGLE_ANALYTICS = 'GOOGLE_ANALYTICS'
export const FIREBASE = 'FIREBASE'
export const AMPLITUDE = 'AMPLITUDE'
export const FACEBOOK = 'FACEBOOK'

// Amplitude
export const AMPLITUDE_ANALYTICS_ID = '33a18db3c1f2cbb9306d75d2274a4da5'

// Facebook
export const FACEBOOK_ANALYTICS_PIXEL_ID = '505782873788325'

// Google
export const GOOGLE_ANALYTICS_ID = 'UA-157888408-1' // @todo update

export const GMT_IDS = {
  [SubPlan.trialFree]: 'GTM-WSWHD4T',
  [SubPlan.trialPaid]: 'GTM-NVCVK2D',
  [SubPlan.subscriptionFull]: 'GTM-5TPZPBS',
  [SubPlan.subscription50OFF]: '',
  [SubPlan.subscription70OFF]: '',
}
