import React, { Component } from 'react'

import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom'

import ROUTES from 'src/router/constants'

import { APPLE, AUTH, STORE } from '../constants/routes'
import CheckoutFlowPage from '../containers'

import { Wrapper } from './styles'

class CheckoutFlowRouter extends Component<RouteComponentProps> {
  public render() {
    return (
      <Wrapper>
        <Switch>
          {[APPLE, AUTH, STORE].map(route => (
            <Route
              exact
              key={route}
              path={route}
              component={CheckoutFlowPage}
            />
          ))}

          <Redirect to={ROUTES.APP}/>
        </Switch>
      </Wrapper>
    )
  }
}

export default withRouter(CheckoutFlowRouter)
