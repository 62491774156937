import Auth from './sections/auth'
import Subscriptions from './sections/subscriptions'
import User from './sections/user'

import { IAPIService } from './types'

class Kit {
  public auth: Auth
  public subscription: Subscriptions
  public user: User

  protected service: IAPIService

  public constructor(service: IAPIService) {
    this.service = service

    this.auth = new Auth(service)
    this.subscription = new Subscriptions(service)
    this.user = new User(service)
  }
}

export default Kit
