import React from 'react'

import { RouteComponentProps, withRouter } from 'react-router-dom'

import { IStoresMap } from 'src/store'
import connector from 'src/decorators/connector'
import { SubPlan } from 'src/constants/subscription/plans'

import { DEFAULT_EXPERIMENT } from '../../constants/experiment'

import {
  Wrapper,
  Button,
  Content,
  Background,
  OfferTitle,
  OfferDescription,
  Offer,
  BackgroundGradient,
} from './style'

type ICheckoutFlowApplePayPaywallProps = RouteComponentProps & ReturnType<typeof storesToProps>

class CheckoutFlowApplePayPaywall extends React.Component<ICheckoutFlowApplePayPaywallProps> {
  public componentDidMount() {
    const { location, checkoutFlowApplePay } = this.props
    checkoutFlowApplePay.initFromURL(location.search, DEFAULT_EXPERIMENT)
  }

  public render() {
    const { checkoutFlowApplePay } = this.props
    return (
      <Wrapper>
        <Background/>
        <BackgroundGradient/>
        <Content>
          <Offer>
            <OfferTitle>
              {checkoutFlowApplePay.plan === SubPlan.trialFree ? (
                <span>7-Day Free Trial</span>
              ) : (
                <>
                  7-Day Trial – <span>1$</span>
                </>
              )}
            </OfferTitle>
            <OfferDescription>
              Try Prisma Unlimited for 7 days, then $29.99/year. Cancel anytime.
            </OfferDescription>
          </Offer>
          <Button onClick={checkoutFlowApplePay.purchase}>
            {checkoutFlowApplePay.plan === SubPlan.trialFree ? 'Start Free Trial' : 'Start Trial'}
          </Button>
        </Content>
      </Wrapper>
    )
  }
}

const storesToProps = ({ store }: IStoresMap, _nextProps: any) => ({
  checkoutFlowApplePay: store.checkoutFlowApplePay,
})

export default withRouter(connector(storesToProps)(CheckoutFlowApplePayPaywall))
