export interface StepperProps {
  totalSteps: number
  currentStep: number
}

export enum StepState {
  Passed = 'passed',
  Active = 'active',
  Inactive = 'inactive',
}
