import { TrialType } from 'src/constants/subscription/trial'
import { STRIPE_ENTITY_CONFIGURATION } from 'src/constants/subscription/config'
import { ISubPlan } from 'src/models/sub-plan'

// ! important: marketing team uses these strings in their landings
export enum SubPlan {
  trialFree = '7-free_yearly_no-discount',
  trialPaid = '7-for-1_yearly_no-discount',
  subscriptionFull = 'no-trial_yearly_no-discount',
  subscription50OFF = 'no-trial_yearly_50-off',
  subscription70OFF = 'no-trial_yearly_70-off',
  default = subscriptionFull,
}

type SubPlanConfigurationOptions = {
  [key in SubPlan]: ISubPlan
}

export const SUB_PLAN_CONFIGURATION: SubPlanConfigurationOptions = {
  [SubPlan.trialFree]: {
    trialType: TrialType.free,
    coupon: '',
  },
  [SubPlan.trialPaid]: {
    trialType: TrialType.paid,
    coupon: '',
  },
  [SubPlan.subscriptionFull]: {
    trialType: TrialType.none,
    coupon: '',
  },
  [SubPlan.subscription50OFF]: {
    trialType: TrialType.none,
    coupon: STRIPE_ENTITY_CONFIGURATION.COUPON_50,
  },
  [SubPlan.subscription70OFF]: {
    trialType: TrialType.none,
    coupon: STRIPE_ENTITY_CONFIGURATION.COUPON_70,
  },
}
