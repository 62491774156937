import * as React from 'react'
import { SvgProps } from './types'

class Svg extends React.Component<SvgProps, {}> {
  public render() {
    const { icon, fill, width, height, square, ...rest } = this.props

    return (
      <svg viewBox={icon.viewBox} {...this.getDimensions()} {...rest}>
        <use xlinkHref={`#${icon.id}`} />
      </svg>
    )
  }

  private getDimensions() {
    const { icon, width, height, square } = this.props
    const { viewBox } = icon
    const [, , viewBoxWidth, viewBoxHeight] = viewBox.split(' ')

    const dimensions = {} as any
    dimensions.width = width !== undefined ? width : viewBoxWidth
    dimensions.height =
      height !== undefined ? height : square ? dimensions.width : viewBoxHeight

    return dimensions
  }
}

export default Svg
